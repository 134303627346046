* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif !important;
    scroll-behavior: smooth;
}

a {
    color: black;
    text-decoration: none;
}

ul {
    list-style: none;
}

body {
    background-color: #fff;
}